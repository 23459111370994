export default {
	name: "admin-lista-noticia",
	data() {
		return {
			arCategorias: [],
			arNoticias: [],
			blLoading: true,
			obForm: {
				titulo: null,
				data: null,
				created_at: null,
				id_categoria: null
			},
			obFormPesquisa: {
				titulo: null,
				data: null,
				created_at: null,
				id_categoria: null
			}
		};
	},
	created() {
		this.listar();
	},
	methods: {
		listar() {
			this.$root.$api.get("noticia/listar").then(response => {
				this.arNoticias = response.retorno.noticias;
				this.arCategorias = response.retorno.categorias;

				this.blLoading = false;
			});
		},
		filtrar() {
			this.obFormPesquisa.titulo = this.obForm.titulo;
			this.obFormPesquisa.id_categoria = this.obForm.id_categoria;

			if (this.obForm.data) {
				this.obFormPesquisa.data = this.obForm.data
					.split("-")
					.reverse()
					.join("/");
			} else {
				this.obFormPesquisa.data = null
			}

			if (this.obForm.created_at) {
				this.obFormPesquisa.created_at = this.obForm.created_at
					.split("-")
					.reverse()
					.join("/");
			} else {
				this.obFormPesquisa.created_at = null
			}
		},
		visualizar(obNoticia) {
			let route = null;

			if (obNoticia.rascunho == 1) {
				route = this.$router.resolve({
					name: "noticia",
					params: {
						slug: obNoticia.slug,
						rascunho: true
					}
				});
			} else {
				route = this.$router.resolve({
					name: "noticia",
					params: {
						slug: obNoticia.slug
					}
				});
			}

			window.open(route.href, "_blank");
		}
	},
	computed: {
		listaNoticia() {
			if (!this.arNoticias) {
				return [];
			}

			return this.arNoticias.filter(obNoticia => {
				return (
					(!this.obFormPesquisa.titulo || obNoticia.titulo.toLowerCase().indexOf(this.obFormPesquisa.titulo.toLowerCase()) >= 0) &&
					(!this.obFormPesquisa.data || obNoticia.data == this.obFormPesquisa.data) &&
					(!this.obFormPesquisa.created_at || obNoticia.created_at == this.obFormPesquisa.created_at) &&
					(!this.obFormPesquisa.id_categoria || obNoticia.id_categoria == this.obFormPesquisa.id_categoria)
				)
			});
		}
	}
};